import { render, staticRenderFns } from "./DepositForDoctors.vue?vue&type=template&id=17937619&scoped=true"
import script from "./DepositForDoctors.vue?vue&type=script&lang=js"
export * from "./DepositForDoctors.vue?vue&type=script&lang=js"
import style0 from "./DepositForDoctors.vue?vue&type=style&index=0&id=17937619&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17937619",
  null
  
)

export default component.exports